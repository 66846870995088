<script>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
export default {
  components: {
    Icon,
  },
  props: {
    modelValue: String,
  },
  setup() {
    const isPwd = ref(true);
    return {
      isPwd,
    };
  },
};
</script>

<template>
  <div class="d-flex w-100 input_container">
    <input
      :type="isPwd ? 'password' : 'text'"
      class="form-control"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <Icon
      :icon="isPwd ? 'akar-icons:eye' : 'akar-icons:eye-slashed'"
      width="16"
      height="16"
      @click="isPwd = !isPwd"
      class="pointer"
    />
  </div>
</template>

<style scoped>
.input_container {
  border-bottom: 1px solid #000 !important;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}
input {
  color: #000;
  border: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  border-radius: 0px !important;
  width: calc(100% - 16px);
}
input:focus {
  box-shadow: none !important;
}
</style>