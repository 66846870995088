<script setup>
import { ref, onMounted } from "vue";
import { Icon } from "@iconify/vue";
import { getCategories } from "@/http/general.js";
import { getContracts, deleteContract } from "@/http/contract";
import { ElNotification, ElMessage } from "element-plus";
const loading = ref(false);
const categories = ref([]);
const contrats = ref([]);
const page = ref(1);
const perPage = ref(10);
const search = ref(null);
const category_id = ref(null);
const lastPage = ref(null);
const from = ref(null);
const to = ref(null);
const total = ref(null);
const currentPage = ref(null);
const baseURL = localStorage.getItem("BASE_URL_OPP");
onMounted(() => {
  fetchCategories();
  fetchContracts();
});
const fetchContracts = async () => {
  loading.value = true;
  await getContracts(page.value, perPage.value, search.value, category_id.value)
    .then((response) => {
      loading.value = false;
      lastPage.value = response.last_page;
      from.value = response.from;
      to.value = response.to;
      total.value = response.total;
      currentPage.value = response.current_page;
      contrats.value = response.data;
    })
    .catch(() => {
      loading.value = false;
      ElNotification({
        title: "Erreur",
        message: "Oops. Les contrats n'ont pas pu être chargés",
        type: "error",
      });
    });
};
const fetchCategories = async () => {
  await getCategories()
    .then((response) => {
      categories.value = response;
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Oops. Les catégories nont pu etre chargés",
        type: "error",
      });
    });
};
const handleCommand = (command) => {
  category_id.value = command;
  fetchContracts();
};
const deleteHandler = async (id) => {
  loading.value = true;
  await deleteContract(id)
    .then(() => fetchContracts())
    .catch(() => {
      loading.value = false;
      ElMessage({
        message: "Oops. Le contrat n'a pu etre supprimé",
        type: "error",
      });
    });
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <a href="#">Opencontrat</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Contrats ( {{ total }} )
        </li>
      </ol>
    </nav>
    <div class="d-flex">
      <SearchInput v-model="search" @onSearch="fetchContracts" />
      <el-dropdown trigger="click" @command="handleCommand">
        <button class="mx-3 btn btn-sm btn-outline-info">
          <Icon icon="akar-icons:filter" :inline="true" />Filtrer
        </button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item :command="null">Tous</el-dropdown-item>
            <el-dropdown-item
              v-for="(category, index) in categories"
              :key="index"
              :command="category.id"
            >
              {{ category?.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <Loader v-if="loading" />
  <div v-else class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3">
    <div v-for="(item, index) in contrats" :key="index" class="col">
      <CardContrat
        :title="item?.name"
        :price="item?.price"
        :image="baseURL + 'storage/' + item?.image"
      >
        <router-link
          :to="`/contrat/${item.id}/visualize`"
          class="btn btn-link btn-sm text-primary"
          >Détails</router-link
        >
        <router-link
          :to="`/contrat/${item.id}/edit`"
          class="btn btn-link btn-sm text-info"
          >Editer</router-link
        >
        <el-popconfirm
          confirm-button-text="Oui"
          cancel-button-text="Non"
          icon-color="red"
          title="Confirmer la suppression de ce contrat"
          @confirm="deleteHandler(item?.id)"
        >
          <template #reference>
            <button class="btn btn-link btn-sm text-danger">Supprimer</button>
          </template>
        </el-popconfirm>
      </CardContrat>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center m-3">
    <ul class="pagination">
      <li>
        <p aria-label="Previous">
          <span aria-hidden="true">«</span>
        </p>
      </li>
      <template v-for="(item, index) in lastPage" :key="index">
        <li :class="item == currentPage ? 'active' : ''">
          <p @click="(page = item), fetchContracts()" class="pointer">
            {{ item }}
          </p>
        </li>
      </template>
      <li>
        <p aria-label="Next">
          <span aria-hidden="true">»</span>
        </p>
      </li>
    </ul>
    <div class="">
      <em>Affichage de {{ from }} à {{ to }} (sur {{ total }} éléments)</em>
    </div>
  </div>
  <div style="position: absolute; bottom: 5%; right: 2%; z-index: 9000">
    <router-link to="/contrat/new">
      <Icon
        icon="akar-icons:circle-plus-fill"
        width="50"
        height="50"
        color="#FF0101"
      />
    </router-link>
  </div>
</template>

<style scoped>
.tabul a {
  color: #2696be;
}
.tabul a.active {
  background-color: #2696be;
  border-radius: 25px;
}
</style>
