import request from "./request";

/**
 *
 * @param {Number} id
 * @returns
 */
export function blockUser(id) {
  return request({
    url: "admin/users/" + id + "/block",
    method: "post",
  });
}

/**
 *
 * @param {Number} id
 * @returns
 */
export function unblockUser(id) {
  return request({
    url: "admin/users/" + id + "/unblock",
    method: "post",
  });
}

/**
 *
 * @param {Number} page
 * @param {Number} perPage
 * @param {String} name
 * @returns
 */
export function getUsers(page, perPage, name) {
  return request({
    url: `admin/users?page=${page}&perPage=${perPage}` + (name? `&name=${name}` : ''),
    method: "get",
  });
}

/**
 *
 * @param {Number} id
 * @returns
 */
export function getUser(id) {
  return request({
    url: "admin/users/" + id,
    method: "get",
  });
}

