<script setup>
import { ref } from "vue";
import { ElNotification } from "element-plus";
import { extractQuestions } from "@/helpers/editor.js";
import { getContract } from "@/http/contract";
import { useRouter, useRoute } from "vue-router";
const route = useRoute();
const router = useRouter();
const active = ref(1);
const baseURL = localStorage.getItem("BASE_URL_OPP");
const questionsCount = ref(0);
const contractData = ref([]);
const questions = ref({});
const loading = ref(false);
const editor = ref(null);
const desc_editor = ref(null);
const next = () => {
  if (active.value++ > 4) active.value = 0;
  if (active.value == 2) {
    questionsCount.value = extractQuestions(editor.value.getText());
    const surveys = contractData.value.contract_surveys;
    for (let item in surveys) {
      questions.value[surveys[item].question_label] = surveys[item].question;
    }
  }
};
const prev = () => {
  if (active.value-- < 1) active.value = 0;
};


const fetchContract = async () => {
  await getContract(route.params.id)
    .then((response) => {
      const value1 = JSON.parse(response.description);
      const value2 = JSON.parse(response.content);
      if (typeof value1 == "string") {
        desc_editor.value.setContents(JSON.parse(value1));
      } else {
        desc_editor.value.setContents(value1);
      }
      if (typeof value2 == "string") {
        editor.value.setContents(JSON.parse(value2));
      } else {
        editor.value.setContents(value2);
      }
      contractData.value = response;
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Oops. Le contrat n'a pu etre chargé",
        type: "error",
      });
      router.go(-1);
    });
};
</script>

<template>
  <div class="d-flex contrat__container">
    <el-steps direction="vertical" :active="active" finish-status="success">
      <el-step title="1" description="Informations sur le contrat">
      </el-step>
      <el-step title="2" description="Rédaction du contrat"></el-step>
      <el-step title="3" description="Questions"></el-step>
      <el-step title="4" description="Images"></el-step>
    </el-steps>
    <div class="card border-0 w-100 h-100">
      <div class="card-body bg-white">
        <div v-show="active == 1">
          <div class="row rows-col-2">
            <div class="col">
              <div class="my-3">
                <label class="form-label">Catégorie</label>
                <input
                  readonly
                  :value="contractData?.category?.name"
                  type="text"
                  class="form-control border-primary border-1"
                />
              </div>
              <div class="my-3">
                <label class="form-label">Nom du contrat</label>
                <input
                  readonly
                  :value="contractData?.name"
                  type="text"
                  class="form-control border-primary border-1"
                />
              </div>
              <div class="my-3">
                <label class="form-label">Description</label>
                <QuillEditor
                  ref="desc_editor"
                  theme="bubble"
                  readOnly
                  toolbar=""
                  @ready="fetchContract()"
                />
              </div>
              <div class="my-3">
                <label class="form-label">Prix (F CFA)</label>
                <input
                  readonly
                  :value="contractData?.price"
                  type="number"
                  class="form-control border-primary border-1"
                />
              </div>
            </div>
            <div class="col align-self-center">
              <img
                src="@/assets/progress.svg"
                class="w-100"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div v-show="active == 2">
          <QuillEditor
            ref="editor"
            theme="bubble"
            readOnly
            toolbar="full"
          />
        </div>
        <div v-if="active == 3">
          <el-empty
            v-if="questionsCount < 1"
            description="Aucune question détectée"
          ></el-empty>
          <div v-else>
            <div class="alert bg-light fw-bold fs-10" role="alert">
              {{ questionsCount.length }} questions détectées.
            </div>
            <div class="row">
              <div
                v-for="(item, index) in contractData.contract_surveys"
                :key="index"
                class="col-6"
              >
                <small for="">{{item.question_label}}</small>
                <input
                  readonly
                  type="text"
                  class="form-control border-primary border-1 my-3 fs-13"
                  :value="item.question"
                  readlonly
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="active == 4">
          <div class="row rows-col-2">
            <div class="col">
              <div class="card" style="width: 1oo%">
                <img
                  :src="baseURL + 'storage/' + contractData?.image"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <el-tag type="info">Image</el-tag>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" style="width: 1oo%">
                <img
                  :src="baseURL + 'storage/' + contractData?.draft_image"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <el-tag type="info">Draft</el-tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div v-if="!loading" class="d-flex justify-content-end">
          <el-button v-if="active > 1" @click="prev">Précédent</el-button>
          <el-button v-if="active < 4" @click="next">Suivant</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
