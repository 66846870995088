import request from './request'

export function storeManager(data) {
  return request({
    url: 'admin/managers',
    method: 'post',
    data
  })
}

export function getManagers(page, perPage, name) {
  return request({
    url: `admin/managers?page=${page}&perPage=${perPage}` + (name? `&name=${name}` : ''),
    method: 'get'
  })
}

export function getManager(id) {
  return request({
    url: `admin/managers/${id}`,
    method: 'get'
  })
}

export function deleteManager(id) {
  return request({
    url: `admin/managers/${id}`,
    method: 'delete'
  })
}