<script setup>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import UserCard from "./components/UserCard";
/* import Activity from "./components/Activity";
import Timeline from "./components/Timeline"; */
import Account from "./components/Account";
const store = useStore();
const user = computed(() => store.getters["user/profile"]);
const activeTab = ref("account");
</script>

<template>
  <div class="app-container">
    <div v-if="user">
      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <user-card :user="user" />
        </el-col>

        <el-col :span="18" :xs="24">
          <el-card>
            <el-tabs v-model="activeTab">
              <!-- <el-tab-pane label="Activity" name="activity">
                <activity />
              </el-tab-pane>
              <el-tab-pane label="Timeline" name="timeline">
                <timeline />
              </el-tab-pane> -->
              <el-tab-pane label="Mon compte" name="account">
                <account :user="user" />
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

