/*eslint no-prototype-builtins: "error"*/
/**
 *
 * @param {string} content
 */
export function extractQuestions(content) {
  //const arr = content.match(/\[\w+\]/g);
  const arr = content.match(/{\w+}/g);
  if (!arr) return 0
  const intent = filterArray(arr);
  return intent;
}

function filterArray(inputArr) {
  let found = new Object();
  const out = inputArr.filter((element) => {
    return Object.prototype.hasOwnProperty.call(found, element)
      ? false
      : (found[element] = true);
  });
  return out;
}
