<script>
import { Sidebar, Navbar } from "@/layout/components";
export default {
  components: {
    Sidebar,
    Navbar,
  },
};
</script>

<template>
  <div class="d-flex justify-content-start">
    <Sidebar />
    <div class="app_outer">
      <Navbar />
      <main class="p-3">
        <router-view />
      </main>
    </div>
  </div>
</template>

<style>
.app_outer {
  width: 100%;
  background-color: #f7f8fc;
}
main {
  height: calc(100vh - 60px);
  overflow: scroll;
}
</style>

