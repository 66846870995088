import request from './request'

export function storeContract(data) {
  return request({
    url: 'admin/contracts',
    method: 'post',
    data,
/*     headers: {
      'Content-Type': 'multipart/form-data'
    } */
  })
}

export function storeImage(id, data) {
  return request({
    url: `admin/contracts/${id}/image`,
    method: 'post',
    data,
  })
}

export function storeDraftImage(id, data) {
  return request({
    url: `admin/contracts/${id}/draft-image`,
    method: 'post',
    data,
  })
}

export function updateContract(id, data) {
  return request({
    url: 'admin/contracts/' + id,
    method: 'put',
    data,
  })
}
/**
 * 
 * @param {Number} page 
 * @param {Number} perPage 
 * @param {String} name 
 * @param {String} category_id - uuid number of category
 * @returns 
 */
export function getContracts(page, perPage, name, category_id) {
  return request({
    url: `admin/contracts?page=${page}&perPage=${perPage}` + (name? `&name=${name}` : '') + (category_id? `&category_id=${category_id}` : ''),
    method: 'get'
  })
}

export function getContract(id) {
  return request({
    url: 'admin/contracts/' + id,
    method: 'get'
  })
}

export function deleteContract(id) {
  return request({
    url: 'admin/contracts/' + id,
    method: 'delete'
  })
}

export function getContractSurveys(id) {
  return request({
    url: 'admin/contracts/' + id + '/surveys',
    method: 'get'
  })
}

export function storeContractSurveys(id, data) {
  return request({
    url: 'admin/contracts/' + id + '/surveys',
    method: 'post',
    data
  })
}