<template>
  <button>
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style scoped>
button {
  background: #20356c;
  border-radius: 50px;
  color: #fff;
  padding: 8px 16px;
  border: 0px !important;
}
</style>
