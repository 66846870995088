<script setup>
import { Icon } from "@iconify/vue";
import { getStats, getRevenue, getBestsellers } from "@/http/general";
import { onMounted, ref } from "vue";
import { ElNotification} from "element-plus";
const clientsCount = ref(0);
const contractsCount = ref(0);
const revenue = ref(0);
const bestsellers = ref(0);
const loading = ref(false);
onMounted(() => {
  fetchStats();
});
const fetchStats = async () => {
  loading.value = true;
  await getStats()
    .then((response) => {
      clientsCount.value = response.clientsCount;
      contractsCount.value = response.contractsCount;
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Oops. Les statistiques n'ont pas pu être chargés",
        type: "error",
      });
    });
    await getRevenue()
    .then((response) => {
      revenue.value = response.revenue;
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Oops. Le chiffre d'affaires n'a pu etre chargé",
        type: "error",
      });
    });
    await getBestsellers()
    .then((response) => {
      bestsellers.value = response;
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Oops. Le chiffre d'affaires n'a pu etre chargé",
        type: "error",
      });
    });
    loading.value = false
};

</script>

<template>
  <div class="card border-0 p-4 mb-5">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
      <div class="col">
        <router-link to="clients">
         <p class="top-title">Clients</p>
        </router-link>
        <div class="d-flex align-items-center">
          <div class="icon-wrapper" style="background: #fff500">
            <Icon icon="akar-icons:people-group" width="30" height="30" />
          </div>
          <h3 class="mx-3">{{clientsCount}}</h3>
        </div>
      </div>
      <div class="col">
        <router-link to="contrats">
          <p class="top-title">Contrats publiés</p>
        </router-link>
        <div class="d-flex align-items-center">
          <div class="icon-wrapper" style="background: #2696be">
            <Icon icon="akar-icons:people-group" width="30" height="30" />
          </div>
          <h3 class="mx-3">{{contractsCount}}</h3>
        </div>
      </div>
      <div class="col">
        <p class="top-title">Chiffre d’affaires</p>
        <div class="d-flex align-items-center">
          <div class="icon-wrapper" style="background: #ff0505">
            <Icon icon="akar-icons:people-group" width="30" height="30" />
          </div>
          <h3 class="mx-3">{{revenue}} </h3> <small>F CFA</small>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading" class="w-100 d-flex justify-content-center align-items-center my-3">
    <Loader title="Chargement en cours"/>
  </div>

  <div class="card border-0">
    <div class="card-header bg-white">
      <h5 style="color: #2696be">Les plus vendus</h5>
    </div>
    <div class="card-body table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Nom du contrat</th>
            <th scope="col">Prix</th>
            <th scope="col">Ventes</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in bestsellers" :key="index">
            <tr>
              <td>{{item?.name}}</td>
              <td>{{item?.price}} F CFA</td>
              <td>{{item?.user_contracts_count}}</td>
              <td>
                <div class="d-flex align-items-center">
                  <router-link :to="`/contrat/${item?.id}/visualize`">
                    <Icon
                      icon="akar-icons:eye"
                      width="20"
                      height="20"
                      class="mx-2"
                    />
                  </router-link>
                  <router-link :to="`/contrat/${item?.id}/edit`">
                    <Icon icon="akar-icons:edit" width="20" height="20" />
                  </router-link>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.icon-wrapper {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.top-title {
  color: #7a7777;
  margin-left: 60px;
}
</style>