import { createRouter, createWebHashHistory } from "vue-router";
import Login from "@/views/login.vue";
import Layout from "@/layout/index";
import NavbarLayout from "@/layout/minimal";
import MesContrats from "@/views/contrats/index";
import NewContrats from "@/views/contrats/new.vue";
import EditContrats from "@/views/contrats/edit.vue";
import VisualizeContrats from "@/views/contrats/visualize.vue";
import Clients from "@/views/clients.vue";
import Acceuil from "@/views/index.vue";
import Gestionnaires from "@/views/gestionnaires/index";
import Profil from "@/views/profil/index.vue";
import Infos from "@/views/infos.vue";
import NotFound from "@/views/notFound.vue";
export const constantRoutes = [
  {
    path: "/connexion",
    name: "Login",
    component: Login,
  },
  {
    path: "/contrat",
    component: NavbarLayout,
    children: [
      {
        path: "new",
        name: "NewContrats",
        component: NewContrats,
      },
      {
        path: ":id/edit",
        name: "EditContrats",
        component: EditContrats,
        props: true,
      },
      {
        path: ":id/visualize",
        name: "VisualizeContrats",
        component: VisualizeContrats,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        component: Acceuil,
        name: "Acceuil",
      },
      {
        path: "contrats",
        component: MesContrats,
        name: "MesContrats",
      },
      {
        path: "clients",
        component: Clients,
        name: "Clients",
      },
      {
        path: "profil",
        component: Profil,
        name: "Profil",
      },
      {
        path: "gestionnaires",
        component: Gestionnaires,
        name: "Gestionnaires",
      },
      {
        path: "infos",
        component: Infos,
        name: "Infos",
      },
    ],
  },
];

export const asyncRoutes = [];


const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes,
});

export function resetRouter() {
  const newRouter = router();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
