import request from './request'

export function storeCategory(data) {
  return request({
    url: 'admin/categories',
    method: 'post',
    data
  })
}

export function getCategories() {
  return request({
    url: 'admin/categories',
    method: 'get'
  })
}

export function deleteCategory(id) {
  return request({
    url: 'admin/categories/' + id,
    method: 'delete'
  })
}


export function updateCategory(data) {
  return request({
    url: 'admin/categories/' + data?.id,
    method: 'put',
    data: {name: data?.name}
  })
}


export function getStats() {
  return request({
    url: 'admin/stats/count',
    method: 'get'
  })
}

export function getRevenue() {
  return request({
    url: 'admin/stats/revenue',
    method: 'get'
  })
}

export function getBestsellers() {
  return request({
    url: 'admin/stats/best-sellers',
    method: 'get'
  })
}

export function getVisites() {
  return request({
    url: 'admin/visitor-stats/today',
    method: 'get'
  })
}

export function getVisitesPerPeriode(start, end) {
  return request({
    url: `admin/visitor-stats?start=${start}&end=${end}`,
    method: 'get'
  })
}