<script setup>
import { onMounted } from "vue";
import {
  getCategories,
  //getVisites,
  getVisitesPerPeriode,
  storeCategory,
  deleteCategory,
  updateCategory,
} from "@/http/general.js";
import { getOffers, updateOffer } from "@/http/offer";
import moment from "@/helpers/moment";

import { ref } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { Icon } from "@iconify/vue";
const activeName = ref("categories");
const openSubscriptionDialog = ref(false);
const catInput = ref("");
const loading = ref(false);
const updateLoader = ref(false);
const categories = ref();
const data = ref();
const actualClicked = ref({});
const visites = ref();
const daterange = ref([]);
const updateData = ref({
  id: null,
  name: "",
});

onMounted(() => {
  fetchCategories();
});

const fetchCategories = async () => {
  updateData.value = {
    id: null,
    name: "",
  };
  loading.value = true;
  await getCategories()
    .then((response) => {
      loading.value = false;
      categories.value = response;
    })
    .catch(() => {
      loading.value = false;
      ElNotification({
        title: "Erreur",
        message: "Oops. Les catégories nont pu etre chargés",
        type: "error",
      });
    });
};

const handleAdd = async () => {
  loading.value = true;
  await storeCategory({ name: catInput.value })
    .then(() => {
      loading.value = false;
      catInput.value = "";
      fetchCategories();
      ElMessage({
        message: "Donnée enregistrée avec succès",
        type: "success",
      });
    })
    .catch(() => {
      loading.value = false;
      ElMessage({
        message: "Oops. La donnée n'a pu etre enregistré. Veuillez réessayer",
        type: "error",
      });
    });
};

const handleUpdate = async () => {
  loading.value = true;
  await updateCategory(updateData.value)
    .then(() => {
      loading.value = false;
      updateData.value = {};
      fetchCategories();
      ElMessage({
        message: "Donnée modifiéé avec succès",
        type: "success",
      });
    })
    .catch(() => {
      loading.value = false;
      ElMessage({
        message: "Oops. La donnée n'a pu etre modifiée. Veuillez réessayer",
        type: "error",
      });
    });
};

const handleDelete = async (id) => {
  loading.value = true;
  await deleteCategory(id)
    .then(() => {
      loading.value = false;
      fetchCategories();
      ElMessage({
        message: "Donnée supprimée avec succès",
        type: "success",
      });
    })
    .catch(() => {
      loading.value = false;
      ElMessage({
        message: "Oops. La donnée n'a pu etre supprimée. Veuillez réessayer",
        type: "error",
      });
    });
};

const handleTabClick = (tab) => {
  if (tab.props.name == "categories") {
    fetchCategories();
  } else if (tab.props.name == "subscription") {
    fetchOffers();
  } else if (tab.props.name == "visites") {
    //fetchVisites();
  }
};

const fetchOffers = async () => {
  loading.value = true;
  await getOffers().then((response) => {
    loading.value = false;
    data.value = response.data;
  });
};

const handleOfferUpdate = async () => {
  updateLoader.value = true;
  await updateOffer(actualClicked.value)
    .then(() => {
      updateLoader.value = false;
      fetchOffers();
      openSubscriptionDialog.value = false;
    })
    .catch(() => {});
};

/* const fetchVisites = async () => {
  loading.value = true;
  await getVisites()
    .then((response) => {
      loading.value = false;
      visites.value = response;
    })
    .catch(() => {
      loading.value = false;
      ElNotification.error(
        "Les statistiques de visites n'ont pas pu etre chargés"
      );
    });
}; */
const fetchVisitesPerPeriode = async () => {
  if (daterange.value) {
    const [start, end] = daterange.value;
    loading.value = true;
    await getVisitesPerPeriode(start.toUTCString(), end.toUTCString())
      .then((response) => {
        loading.value = false;
        visites.value = response;
      })
      .catch(() => {
        loading.value = false;
        ElNotification.error(
          "Les statistiques de visites n'ont pas pu etre chargés"
        );
      });
  }
};
</script>
<template>
  <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
    <el-tab-pane label="Catégories" name="categories">
      <Loader v-if="loading" />
      <div v-else class="d-flex flex-wrap">
        <div class="label">
          <TextInput
            class="border-0"
            placeholder="Ajouter une catégorie"
            style="min-width: 150px"
            v-model="catInput"
          />
          <button class="p-0 border-0 bg-transparent">
            <Icon icon="carbon:add-filled" :inline="true" @click="handleAdd" />
          </button>
        </div>

        <div v-for="(category, index) in categories" :key="index" class="label">
          <TextInput
            v-if="category.id == updateData.id"
            v-model="updateData.name"
            class="border-0"
          />
          <span v-else class="me-3">{{ category.name }}</span>
          <button class="p-0 border-0 bg-transparent">
            <Icon
              v-if="category.id != updateData.id"
              icon="ic:sharp-mode-edit"
              :inline="true"
              @click="updateData = category"
            />
            <Icon
              v-if="category.id == updateData.id"
              icon="akar-icons:circle-check-fill"
              :inline="true"
              @click="handleUpdate"
            />
          </button>
          <el-popconfirm
            confirm-button-text="Oui"
            cancel-button-text="Non"
            icon-color="red"
            title="Supprimer cette catégorie ?"
            @confirm="handleDelete(category.id)"
          >
            <template #reference>
              <button
                v-if="category?.contracts_count == 0"
                class="p-0 border-0 bg-transparent"
              >
                <Icon icon="ant-design:close-circle-twotone" :inline="true" />
              </button>
            </template>
          </el-popconfirm>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Abonnements" name="subscription">
      <Loader v-if="loading" />
      <div v-else class="block">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) of data"
            :key="index"
            placement="top"
          >
            <el-card>
              <h4 class="text-primary">{{ item?.name }}</h4>
              <div class="list-item">{{ item?.description }}</div>
              <div class="list-item">Prix : {{ item?.price }}</div>
              <div class="list-item">
                Réduction annuelle : {{ item?.annual_reduction }} %
              </div>
              <div class="list-item">
                Nombre de contrats : {{ item?.contracts_number }}
              </div>
              <div class="list-item">
                Nombre d'utilisateurs :
                {{ item?.beneficiary_users ?? "illimité" }}
              </div>
              <div class="list-item">
                {{
                  item?.electronic_signature
                    ? "Signature éléctronique des documents"
                    : null
                }}
              </div>
              <div class="list-item">
                {{ item?.collaboration ? "Collaboration" : null }}
              </div>
              <div class="list-item">
                {{
                  item?.custom_contracts
                    ? "Vos propres modèles à utiliser par votre équipe."
                    : null
                }}
              </div>
              <div class="list-item">
                {{
                  item?.collaboration
                    ? "Collaborez avec autant d’utilisateurs"
                    : null
                }}
              </div>
              <div class="list-item">
                {{ item?.personal_space ? "Espace personnel" : null }}
              </div>
              <div class="list-item">
                {{
                  item?.unlimited_free_contracts
                    ? "Accès aux modèles de documents gratuits en illimité"
                    : null
                }}
              </div>
              <div class="list-item">
                {{
                  item?.support
                    ? "Renseignement et assistance dans tous les domaines de droit"
                    : null
                }}
              </div>
              <div class="list-item">
                {{
                  item?.questions_chat ? "Questions en illimité par chat" : null
                }}
              </div>
              <div class="list-item">
                {{
                  item?.questions_phone
                    ? "Questions en illimité par téléphone"
                    : null
                }}
              </div>
              <div class="list-item">
                {{
                  item?.questions_mail ? "Questions en illimité par mail" : null
                }}
              </div>
              <div class="w-100 d-flex justify-content-end">
                <el-button
                  class="text-white"
                  type="success"
                  @click="
                    (actualClicked = item), (openSubscriptionDialog = true)
                  "
                  >Mettre à jour</el-button
                >
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-tab-pane>
    <el-tab-pane label="Statistiques des visites" name="visites">
      <div class="card border-0 table-responsive align-items-center">
        <div class="d-flex align-items-center flex-wrap my-3" style="gap: 15px">
          <div class="mb-2">Sélectionner une période</div>
          <el-date-picker
            size="large"
            v-model="daterange"
            type="daterange"
            range-separator="à"
            start-placeholder="Date de début"
            end-placeholder="Date de fin"
          />
          <el-button
            v-if="daterange.length > 1"
            type="primary"
            size="large"
            @click="fetchVisitesPerPeriode"
            >Rechercher</el-button
          >
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Date</th>
              <th scope="col">Nombre</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) in visites" :key="index">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ moment(item?.date).format("Do MMMM YYYY") }}</td>
                <td>{{ item?.count }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </el-tab-pane>
  </el-tabs>
  <el-dialog
    v-model="openSubscriptionDialog"
    title="Modification d'offre"
    center
  >
    <el-form label-width="auto" label-position="top">
      <el-form-item label="Prix (F CFA)">
        <el-input v-model="actualClicked.price" size="large"></el-input>
      </el-form-item>
      <el-form-item label="Réduction en %">
        <el-input
          v-model="actualClicked.annual_reduction"
          size="large"
        ></el-input>
      </el-form-item>
      <el-form-item label="Nombre de contrats">
        <el-input
          v-model="actualClicked.contracts_number"
          size="large"
        ></el-input>
      </el-form-item>
      <el-form-item label="Nombre d'utilisateurs">
        <el-input
          v-model.number="actualClicked.beneficiary_users"
          size="large"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          :loading="updateLoader"
          @click="handleOfferUpdate()"
          type="primary"
          >Modifier</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
}

.label {
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 5px;
  background: rgba(38, 150, 190, 0.2);
  color: rgb(38, 150, 190);
}

button {
  color: inherit;
}
.list-item {
  font-size: 12px;
  margin: 10px 0;
}
</style>
