<script setup>
import { ref, onMounted } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { getCategories } from "@/http/general.js";
import { extractQuestions } from "@/helpers/editor.js";
import {
  updateContract,
  getContract,
  storeImage,
  storeDraftImage,
} from "@/http/contract";
import { useRouter, useRoute } from "vue-router";
const imageUploadRef = ref(null);
const draftUploadRef = ref(null);
const router = useRouter();
const route = useRoute();
const active = ref(0);
const baseURL = localStorage.getItem("BASE_URL_OPP");
const categories = ref([]);
const questionsCount = ref(0);
const contractData = ref([]);
const questions = ref({});
const loading = ref(false);
const editor = ref(null);
const desc_editor = ref(null);
const imgobjectURL = ref(null);
const draftobjectURL = ref(null);
const publishForm = ref({
  price: 0,
  name: "",
  content: null,
  description: "",
  category_id: "",
});
const next = () => {
  if (active.value++ > 4) active.value = 0;
  if (active.value == 2) {
    questionsCount.value = extractQuestions(editor.value.getText());
    const arr1 = [];
    for (let key in questionsCount.value) {
      arr1.push(questionsCount.value[key]);
    }
    const temp = {};
    arr1.forEach((element) => {
      let found = false;
      for (let item in questions.value) {
        if (item == element) {
          temp[item] = questions.value[item];
          found = true;
        }
      }
      if (found == false) temp[element] = "";
    });
    questions.value = temp;
  }
  if (active.value == 4) handlePublish();
};
const prev = () => {
  if (active.value-- < 1) active.value = 0;
};
const handlePublish = async () => {
  let surveys = [];
  let canContinue = true;
  let i = -1;
  for (let question_label in questions.value) {
    if (questions.value[question_label] == "") {
      ElNotification({
        message: `Le champ ${question_label} doit etre rempli`,
        type: "warning",
        duration: 0,
        offset: i * 80,
      });
      canContinue = false;
      active.value = 2;
    }
    const row = {
      question: questions.value[question_label],
      question_label: question_label,
    };
    surveys.push(row);
    i++;
  }
  if (canContinue) {
    if (publishForm.value.price < 0 || publishForm.value.price == "") {
      publishForm.value.price = 0;
    }
    publishForm.value.content = JSON.stringify(publishForm.value.content);
    publishForm.value.description = JSON.stringify(
      publishForm.value.description
    );
    loading.value = true;
    const surveysToString = JSON.stringify(surveys);

    await updateContract(contractData.value.id, {
      ...publishForm.value,
      surveys: surveysToString,
    })
      .then(() => {
        loading.value = false;
        active.value = 0;
        ElMessage({
          message: "Contrat modifié avec succès",
          type: "success",
        });
        router.push("/contrats");
      })
      .catch(() => {
        loading.value = false;
        ElMessage({
          message:
            "Oops. Le contrat n'a pu etre enregistré. Veuillez vérifier tous les champs et réessayer",
          type: "error",
        });
        active.value = 0;
      });
  }
};

onMounted(() => {
  fetchCategories();
});

const fetchContract = async () => {
  await getContract(route.params.id)
    .then(async (response) => {
      publishForm.value.category_id = response.category_id;
      publishForm.value.name = response.name;
      publishForm.value.price = response.price;

      const value1 = JSON.parse(response.description);
      if (typeof value1 == "string") {
        desc_editor.value.setContents(JSON.parse(value1));
      } else {
        desc_editor.value.setContents(value1);
      }
      const value2 = JSON.parse(response.content);
      if (typeof value2 == "string") {
        editor.value.setContents(JSON.parse(value2));
      } else {
        editor.value.setContents(value2);
      }

      contractData.value = response;
      const surveys = contractData.value.contract_surveys;
      for (let item in surveys) {
        questions.value[surveys[item].question_label] = surveys[item].question;
      }
    })
    .catch((e) => {
      console.log(e);
      ElNotification({
        title: "Erreur",
        message: "Oops. Le contrat n'a pu etre chargé",
        type: "error",
      });
      router.go(-1);
    });
};

const fetchCategories = async () => {
  await getCategories()
    .then((response) => {
      categories.value = response;
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Oops. Les catégories nont pu etre chargés",
        type: "error",
      });
    });
};
const handleImage = {
  FileImgTarget: () => imageUploadRef.value.click(),
  DraftImgTarget: () => draftUploadRef.value.click(),
};

const handleInputChange = {
  Img: async () => {
    if (imageUploadRef.value.files[0].size > 2_097_152) {
      ElMessage({
        message: "La taille de l'image ne doit pas excéder 2Mb",
        type: "error",
      });
    } else {
      const formdata = new FormData();
      formdata.append("image", imageUploadRef.value.files[0]);
      await storeImage(contractData.value?.id, formdata)
        .then(() => {
          ElMessage({
            message: "Image chargée avec succès",
            type: "success",
          });
          imgobjectURL.value = URL.createObjectURL(
            imageUploadRef.value.files[0]
          );
        })
        .catch(() => {
          ElMessage({
            message: "Image non chargée. Veuillez réessayer",
            type: "error",
          });
        });
    }
  },
  Draft: async () => {
    if (draftUploadRef.value.files[0].size > 2_097_152) {
      ElMessage({
        message: "La taille de l'image ne doit pas excéder 2Mb",
        type: "error",
      });
    } else {
      const formdata = new FormData();
      formdata.append("image", draftUploadRef.value.files[0]);
      await storeDraftImage(contractData.value?.id, formdata)
        .then(() => {
          ElMessage({
            message: "Image chargée avec succès",
            type: "success",
          });
          draftobjectURL.value = URL.createObjectURL(
            draftUploadRef.value.files[0]
          );
        })
        .catch(() => {
          ElMessage({
            message: "Image non chargée. Veuillez réessayer",
            type: "error",
          });
        });
    }
  },
};
</script>

<template>
  <div class="d-flex contrat__container">
    <el-steps direction="vertical" :active="active" finish-status="success">
      <el-step title="Etape 1" description="Informations sur le contrat">
      </el-step>
      <el-step title="Etape 2" description="Rédaction du contrat"></el-step>
      <el-step title="Etape 3" description="Questions"></el-step>
      <el-step title="Etape 4" description="Images"></el-step>
    </el-steps>
    <div class="card border-0 w-100">
      <div class="card-body bg-white">
        <div v-show="active == 0">
          <div class="row rows-col-2">
            <div class="col">
              <div class="my-3">
                <label class="form-label">Catégorie</label>
                <select
                  v-model="publishForm.category_id"
                  class="form-select border-primary border-1"
                  aria-label="Default select example"
                >
                  <option selected>Sélectionner une catégorie</option>
                  <option
                    v-for="(cat, index) in categories"
                    :key="index"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
              </div>
              <div class="my-3">
                <label class="form-label">Nom du contrat</label>
                <input
                  v-model="publishForm.name"
                  type="text"
                  class="form-control border-primary border-1"
                />
              </div>
              <div class="my-3">
                <label class="form-label">Description</label>
                <QuillEditor
                  ref="desc_editor"
                  theme="snow"
                  toolbar=""
                  v-model:content="publishForm.description"
                  @ready="fetchContract()"
                />
              </div>
              <div class="my-3">
                <label class="form-label">Prix (F CFA)</label>
                <input
                  v-model="publishForm.price"
                  type="number"
                  class="form-control border-primary border-1"
                />
              </div>
            </div>
            <div class="col align-self-center">
              <img
                src="@/assets/progress.svg"
                class="w-100"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div v-show="active == 1">
          <div class="alert bg-light border-0 fw-bold fs-10" role="alert">
            Veuillez délimiter les zones de réponses avec des accolades et la
            constante de question à l’intérieur.
            <ul>
              <li>
                La constante est composée de n'importe quel caractère
                alphanumérique de l'alphabet latin.
              </li>
              <li>La constante ne comporte pas d'espaces</li>
              <li>La constante ne comporte pas d'accents</li>
            </ul>
          </div>
          <QuillEditor
            ref="editor"
            theme="bubble"
            toolbar="full"
            placeholder="Commencer la saisie ici"
            v-model:content="publishForm.content"
          />
        </div>
        <div v-if="active == 2">
          <el-empty
            v-if="questionsCount < 1"
            description="Aucune question détectée"
          ></el-empty>
          <div v-else>
            <div class="alert bg-light fw-bold fs-10" role="alert">
              Nous avons détecté {{ questionsCount.length }}
              questions. Veuillez ajouter ces questions dans les champs suivants
              :
            </div>
            <div class="row">
              <div
                v-for="(item, index) in questionsCount"
                :key="index"
                class="col-6"
              >
                <input
                  v-model="questions[item]"
                  type="text"
                  class="form-control border-primary border-1 my-3 fs-13"
                  :placeholder="item"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="active == 3">
          <div class="row rows-col-2">
            <div class="col">
              <div class="card" style="width: 1oo%">
                <img
                  v-if="imgobjectURL"
                  :src="imgobjectURL"
                  class="card-img-top"
                  alt="..."
                />
                <img
                  v-else
                  :src="baseURL + 'storage/' + contractData?.image"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <input
                    type="file"
                    ref="imageUploadRef"
                    class="d-none"
                    @change="handleInputChange['Img']"
                  />
                  <el-button
                    type="primary"
                    @click="handleImage['FileImgTarget']"
                  >
                    changer l'image
                  </el-button>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card" style="width: 1oo%">
                <img
                  v-if="draftobjectURL"
                  :src="draftobjectURL"
                  class="card-img-top"
                  alt="..."
                />
                <img
                  v-else
                  :src="baseURL + 'storage/' + contractData?.draft_image"
                  class="card-img-top"
                  alt="..."
                />
                <div class="card-body">
                  <input
                    type="file"
                    ref="draftUploadRef"
                    class="d-none"
                    @change="handleInputChange['Draft']"
                  />
                  <el-button
                    type="primary"
                    @click="handleImage['DraftImgTarget']"
                    >changer le draft</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="active == 4"
          class="w-100 d-flex justify-content-center align-items-center"
        >
          <Loader v-if="loading" />
        </div>
      </div>
      <div class="card-footer">
        <div v-if="!loading" class="d-flex justify-content-end">
          <el-button v-if="active > 0" @click="prev">Précédent</el-button>
          <el-button v-if="active < 3" @click="next">Suivant</el-button>
          <el-button v-if="active == 3" @click="next">Modifier</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
