<script setup>
import { reactive, ref } from "vue";
import { updatePassword } from "@/http/auth";
import { ElMessage } from "element-plus";

const ruleFormRef = ref();
const ruleForm = reactive({
  password: "",
  new_password: "",
  new_password_confirmation: "",
});

const passwordLoading = ref(false);

const validatePass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Veuillez entrer le mot de passe"));
  } else if (value.length < 6) {
    callback(new Error("Veuillez entrer au moins 6 caracteres"));
  } else {
    if (ruleForm.new_password_confirmation !== "") {
      ruleFormRef.value.validateField("new_password_confirmation");
    }
    callback();
  }
};
const validatePass2 = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Veuillez entrer à nouveau le mot de passe"));
  } else if (value !== ruleForm.new_password) {
    callback(new Error("Les deux entrées ne correspondent pas!"));
  } else {
    callback();
  }
};

const rules = reactive({
  password: [{ required: true, message: "Champ requis", trigger: "blur" }],
  new_password: [{ validator: validatePass, trigger: "blur" }],
  new_password_confirmation: [{ validator: validatePass2, trigger: "blur" }],
});


const update_pwd = async () => {
  if (!ruleFormRef.value) return;
  await ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      passwordLoading.value = true;
      await updatePassword(ruleForm)
        .then(() => {
          ruleFormRef.value.resetFields();
          passwordLoading.value = false;
          ElMessage({
            message: "Mot de passe changé avec succès",
            type: "success",
            duration: 5 * 1000,
          });
        })
        .catch(() => {
          ElMessage({
            message: "Mot de passe invalide",
            type: "error",
          });
          passwordLoading.value = false;
        });
    }
  });
};

</script>

<template>
  <div>
    <el-form
      ref="ruleFormRef"
      label-position="top"
      label-width="150px"
      :model="ruleForm"
      status-icon
      :rules="rules"
    >
      <el-form-item label="Ancien mot de passe" prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          placeholder="Ancien mot de passe"
          size="default"
        />
      </el-form-item>
      <el-form-item label="Nouveau mot de passe" prop="new_password">
        <el-input
          v-model="ruleForm.new_password"
          type="password"
          placeholder="Nouveau mot de passe"
          size="default"
        />
      </el-form-item>
      <el-form-item label="Confirmation" prop="new_password_confirmation">
        <el-input
          v-model="ruleForm.new_password_confirmation"
          type="password"
          placeholder="Confirmation du nouveau mot de passe"
          size="default"
        />
      </el-form-item>
      <el-form-item>
        <el-button :loading="passwordLoading" id="buttons" @click="update_pwd()"
          >Changer mot de passe</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<style scoped>
#buttons {
  color: #2696be;
  border-color: #2696be;
}
</style>