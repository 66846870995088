<script setup>
import { computed, onMounted, ref } from "vue";
import { getManagers, deleteManager } from "@/http/managers";
import { useStore } from "vuex";
import { ElNotification, ElMessage } from "element-plus";
import AjoutModal from "./addModal.vue"
const store = useStore();
const isUserRole = computed(() => store.getters["user/isUserRole"]("admin"));
const lastPage = ref("");
const from = ref("");
const to = ref("");
const total = ref("");
const page = ref(1);
const perPage = ref(10);
const search = ref(null);
const currentPage = ref("");
const loading = ref(false);
const tableData = ref([]);
const setModal = ref(false)
onMounted(() => {
  fetchManagers();
});
const fetchManagers = async () => {
  loading.value = true;
  await getManagers(page.value, perPage.value, search.value)
    .then((response) => {
      loading.value = false;
      lastPage.value = response.last_page;
      from.value = response.from;
      to.value = response.to;
      total.value = response.total;
      currentPage.value = response.current_page;
      tableData.value = response.data;
    })
    .catch(() => {
      loading.value = false;
      ElNotification({
        title: "Erreur",
        message: "Oops. Les gestionnaires n'ont pas pu être chargés",
        type: "error",
      });
    });
};
const handleDelete = async (id) => {
  loading.value = true;
  await deleteManager(id)
    .then(() => {
      loading.value = false;
      fetchManagers();
    })
    .catch(() => {
      loading.value = false;
      ElMessage({
        showClose: true,
        message:
          "Oops. Le manager n'a pas pu etre supprimé. Veuillez réessayer",
        type: "error",
      });
    });
};

</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Opencontrat</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Gestionnaires ( {{ total }} )
        </li>
      </ol>
    </nav>
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <Loader v-if="loading" />
  <div v-if="!loading" class="my-3 d-flex justify-content-end">
    <button class="btn btn-sm btn-info" @click="setModal = true">
      Ajouter un gestionnaire
    </button>
  </div>

  <div v-if="!loading" class="card border-0 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Nom et prénoms</th>
          <th scope="col">Email</th>
          <th scope="col">Ajouté le</th>
          <th scope="col">Rôle</th>
          <th v-if="isUserRole" scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in tableData" :key="index">
          <tr>
            <td>{{item.name}}</td>
            <td>{{item.email}}</td>
            <td>{{new Date(item.created_at).toLocaleDateString()}}</td>
            <td>{{ item.role }}</td>
            <td v-if="isUserRole">
              <el-popconfirm
                confirm-button-text="Oui"
                cancel-button-text="Non"
                icon-color="red"
                title="Supprimer ce manager"
                @confirm="handleDelete(item?.id)"
              >
                <template #reference>
                  <button class="btn btn-sm btn-outline-danger">
                    Supprimer
                  </button>
                </template>
              </el-popconfirm>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <AjoutModal v-model:open="setModal" @onFinish="fetchManagers"/>
</template>

<style>

</style>