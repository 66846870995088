<script setup>
import { ref, onMounted } from "vue";
import { ElNotification, ElMessage } from "element-plus";
import { getCategories } from "@/http/general.js";
import { extractQuestions } from "@/helpers/editor.js";
import { storeContract } from "@/http/contract";
import { useRouter } from "vue-router"
const imageUploadRef = ref(null);
const draftUploadRef = ref(null);
const router = useRouter()
const active = ref(0);
const categories = ref([]);
const questionsCount = ref(0);
const questions = ref({});
const loading = ref(false);
const editor = ref(null);
const publishForm = ref({
  price: 0,
  name: "",
  content: null,
  description: "",
  image: "",
  draft_image: "",
  category_id: "",
});
const priceChecker = ref(true);
const next = () => {
  if (active.value++ > 3) active.value = 0;
    if (active.value == 2) {
    questionsCount.value = extractQuestions(editor.value.getText());
    const arr1 = [];
    for (let key in questionsCount.value) {
      arr1.push(questionsCount.value[key]);
    }
    const temp = {};
    arr1.forEach((element) => {
      let found = false;
      for (let item in questions.value) {
        if (item == element) {
          temp[item] = questions.value[item];
          found = true;
        }
      }
      if (found == false) temp[element] = "";
    });
    questions.value = temp;
  }
  if (active.value == 3) handlePublish();
};
const prev = () => {
  if (active.value-- < 1) active.value = 0;
};
const handlePublish = async () => {
  let surveys = [];
  let canContinue = true;
  let i = -1;
  for (let question_label in questions.value) {
    if (questions.value[question_label] == "") {
      ElNotification({
        message: `Le champ ${question_label} doit etre rempli`,
        type: "warning",
        duration: 0,
        offset: i * 80
      });
      canContinue = false;
      active.value = 2;
    }
    const row = {
      question: questions.value[question_label],
      question_label: question_label,
    };
    surveys.push(row);
    i += 1;
  }
  if (canContinue) {
    if (publishForm.value.price < 0 || publishForm.value.price == '') publishForm.value.price = 0
    publishForm.value.content = JSON.stringify(publishForm.value.content)
    publishForm.value.description = JSON.stringify(publishForm.value.description)
    loading.value = true;
    const formdata = new FormData()
    for (let element in publishForm.value) {
      formdata.append(element, publishForm.value[element])
    }
    formdata.append('surveys', JSON.stringify(surveys))
    await storeContract(formdata)
      .then(() => {
        loading.value = false;
        active.value = 0;
        ElMessage({
          message: "Contrat créé avec succès",
          type: "success",
        });
        router.push("/contrats")
      })
      .catch(() => {
        loading.value = false;
        ElMessage({
          message:
            "Oops. Le contrat n'a pu etre enregistré. Veuillez vérifier tous les champs et réessayer",
          type: "error",
        });
        active.value = 0;
      });
  }
};

onMounted(() => {
  fetchCategories();
});

const fetchCategories = async () => {
  await getCategories()
    .then((response) => {
      categories.value = response;
    })
    .catch(() => {
      ElNotification({
        title: "Erreur",
        message: "Oops. Les catégories nont pu etre chargés",
        type: "error",
      });
    });
};

const handleImage = {
  FileImgTarget: () => imageUploadRef.value.click(),
  DraftImgTarget: () => draftUploadRef.value.click(),
};

const handleInputChange = {
  Img: () => {
    if (imageUploadRef.value.files[0].size > 2_097_152) {
      ElMessage({
        message: "La taille de l'image ne doit pas excéder 2Mb",
        type: "error",
      });
    } else {
      publishForm.value.image = imageUploadRef.value.files[0];
    }
  },
  Draft: () => {
    if (draftUploadRef.value.files[0].size > 2_097_152) {
      ElMessage({
        message: "La taille de l'image ne doit pas excéder 2Mb",
        type: "error",
      });
    } else {
      publishForm.value.draft_image = draftUploadRef.value.files[0];
    }
  },
};
</script>

<template>
  <div class="d-flex contrat__container">
    <el-steps direction="vertical" :active="active" finish-status="success">
      <el-step title="Etape 1" description="Informations sur le contrat">
      </el-step>
      <el-step title="Etape 2" description="Rédaction du contrat"></el-step>
      <el-step title="Etape 3" description="Questions"></el-step>
    </el-steps>
    <div class="card border-0 w-100">
      <div class="card-body bg-white">
        <div v-show="active == 0">
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2">
            <div class="col">
              <div class="my-3">
                <label class="form-label">Catégorie</label>
                <select
                  v-model="publishForm.category_id"
                  class="form-select border-primary border-1"
                  aria-label="Default select example"
                >
                  <option selected>Sélectionner une catégorie</option>
                  <option
                    v-for="(cat, index) in categories"
                    :key="index"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
              </div>
              <div class="my-3">
                <label class="form-label">Nom du contrat</label>
                <input
                  v-model="publishForm.name"
                  type="text"
                  class="form-control border-primary border-1"
                />
              </div>
              <div class="my-3">
                <label class="form-label">Description</label>
                <QuillEditor
                  theme="snow"
                  toolbar=""
                  v-model:content="publishForm.description"
                />
              </div>
              <div class="my-3">
                <div class="form-check form-switch">
                  <input
                    v-model="priceChecker"
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    :checked="priceChecker"
                  />
                  <label class="form-check-label" for="flexSwitchCheckChecked"
                    >Gratuit</label
                  >
                </div>
              </div>
              <div v-if="!priceChecker" class="my-3">
                <label class="form-label">Prix (F CFA)</label>
                <input
                  v-model="publishForm.price"
                  type="number"
                  class="form-control border-primary border-1"
                />
              </div>
              <div class="row my-3">
                <div class="col-6">
                  <input
                    type="file"
                    ref="imageUploadRef"
                    class="d-none"
                    @change="handleInputChange['Img']"
                  />
                  <el-button
                    type="primary"
                    @click="handleImage['FileImgTarget']"
                    >uploader l'image</el-button
                  >
                  <small class="my-1 d-block text-secondary">{{
                    publishForm.image.name
                  }}</small>
                </div>
                <div class="col-6">
                  <input
                    type="file"
                    ref="draftUploadRef"
                    class="d-none"
                    @change="handleInputChange['Draft']"
                  />
                  <el-button
                    type="primary"
                    @click="handleImage['DraftImgTarget']"
                    >uploader le draft</el-button
                  >
                  <small class="my-1 d-block text-secondary">{{
                    publishForm.draft_image.name
                  }}</small>
                </div>
              </div>
            </div>
            <div class="col align-self-center d-none d-md-block">
              <img
                src="@/assets/progress.svg"
                class="w-100"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div v-show="active == 1">
          <div class="alert bg-light border-0 fw-bold fs-10" role="alert">
            Veuillez délimiter les zones de réponses avec des accolades et la
            variable de question à l’intérieur.
            <ul>
              <li>
                La variable est composée de n'importe quel caractère
                alphanumérique de l'alphabet latin
              </li>
              <li>La variable ne comporte pas d'espaces</li>
              <li>La variable ne comporte pas d'accents</li>
            </ul>
          </div>
          <QuillEditor
            ref="editor"
            theme="bubble"
            toolbar="full"
            placeholder="Commencer la saisie ici"
            v-model:content="publishForm.content"
          />
        </div>
        <div v-if="active == 2">
          <el-empty
            v-if="questionsCount < 1"
            description="Aucune question détectée"
          ></el-empty>
          <div v-else>
            <div class="alert bg-light fw-bold fs-10" role="alert">
              Nous avons détecté {{ questionsCount.length }}
              questions. Veuillez ajouter ces questions dans les champs suivants
              :
            </div>
            <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2">
              <div
                v-for="(item, index) in questionsCount"
                :key="index"
                class="col"
              >
                <input
                  v-model="questions[item]"
                  type="text"
                  class="form-control border-primary border-1 my-3 fs-13"
                  :placeholder="item"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="w-100 d-flex justify-content-center align-items-center"
          v-if="active == 3"
        >
          <Loader v-if="loading" />
        </div>
      </div>
      <div class="card-footer">
        <div v-if="!loading" class="d-flex justify-content-end">
          <el-button v-if="active > 0" @click="prev">Précédent</el-button>
          <el-button v-if="active < 2" @click="next">Suivant</el-button>
          <el-button v-if="active == 2" @click="next">Publier</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
