<script setup>
import { defineProps, defineEmits, reactive, ref } from "vue";
import { storeManager } from "@/http/managers";
import { validEmail } from "@/helpers/validate";
defineProps({
  open: {
    required: true,
    type: Boolean,
  },
  modelValue: {
    required: false,
  },
});
const emit = defineEmits(["update:open", ["onSearch"]]);
const loading = ref(false);
const error = ref(false);
const ruleForm = reactive({
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  role: "",
});
const ruleFormRef = ref();
const resetForm = () => {
  ruleForm.name = ""
  ruleForm.email = ""
  ruleForm.password = ""
  ruleForm.password_confirmation = ""
  ruleForm.role = ""
};
const validatePass = (rule, value, callback) => {
  if (value.length < 7) {
    callback(
      new Error("Veuillez saisir un mot de passe de 6 caractères au moins")
    );
  } else {
    if (ruleForm.password_confirmation !== "") {
      if (!ruleFormRef.value) return;
      ruleFormRef.value.validateField("password_confirmation", () => null);
    }
    callback();
  }
};
const validatePass2 = (rule, value, callback) => {
  if (value !== ruleForm.password) {
    callback(new Error("Valeur non identique au mot de passe"));
  } else {
    callback();
  }
};
const checkEmail = (rule, value, callback) => {
  if (!validEmail(value)) {
    return callback(new Error("Adresse mail invalide"));
  } else {
    callback();
  }
};

const rules = reactive({
  name: [
    { required: true, message: "Veuillez saisir le nom", trigger: "blur" },
  ],
  password: [
    { required: true, message: "Champ requis", trigger: "blur" },
    { validator: validatePass, trigger: "blur" },
  ],
  password_confirmation: [
    { required: true, message: "Champ requis", trigger: "blur" },
    { validator: validatePass2, trigger: "blur" },
  ],
  email: [
    { required: true, message: "Champ requis", trigger: "blur" },
    { validator: checkEmail, trigger: "blur" },
  ],
  role: [{ required: true, message: "Champ requis", trigger: "blur" }],
});

const handleCreate = async (formEl) => {
  if (!formEl) return;
  await formEl.validate(async (valid, fields) => {
    if (valid) {
      loading.value = true;
      await storeManager(ruleForm)
        .then(() => {
          loading.value = false;
          resetForm()
          emit("onFinish");
          emit("update:open", false);
        })
        .catch(() => {
          loading.value = false;
          error.value = true;
        });
    } else {
      console.log("error submit!", fields);
    }
  });
};
</script>


<template>
  <el-dialog
    :model-value="open"
    title="Création de gestionnaires"
    center
    :destroy-on-close="true"
    @close="resetForm(), $emit('update:open', false)"
  >
    <el-alert
      v-if="error"
      title="Vérifiez que tous les champs demandés sont bien remplis"
      type="error"
    />
    <el-form
      :model="ruleForm"
      label-width="auto"
      label-position="top"
      ref="ruleFormRef"
      :rules="rules"
    >
      <el-form-item label="Nom et Pénoms" prop="name">
        <el-input v-model="ruleForm.name" size="large"></el-input>
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input v-model="ruleForm.email" size="large"></el-input>
      </el-form-item>
      <el-form-item label="Mot de passe" prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          size="large"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="Confirmation du mot de passe"
        prop="password_confirmation"
      >
        <el-input
          v-model="ruleForm.password_confirmation"
          type="password"
          size="large"
        ></el-input>
      </el-form-item>
      <el-form-item label="Rôle" prop="role">
        <el-select
          class="w-100"
          v-model="ruleForm.role"
          size="large"
          placeholder="Sélectionner le rôle a affecter a cet utilisateur"
        >
          <el-option label="Administrateur" value="admin"></el-option>
          <el-option label="Assistant" value="manager"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <Loader v-if="loading" />
      <span v-else class="dialog-footer">
        <el-button @click="resetForm(), $emit('update:open', false)">Annuler</el-button>
        <el-button type="primary" @click="handleCreate(ruleFormRef)"
          >Envoyer</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>


<style scoped>
input:focus {
  box-shadow: inherit !important;
}
</style>