import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission.js'
import { TextInput, PasswordInput, Select, Button, SearchInput, CardContrat, ConfirmationModal, Loader, PanThumb } from './components/index'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "./styles/index.scss"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { size: 'small' })
  .component('TextInput', TextInput)
  .component('PasswordInput', PasswordInput)
  .component('Button', Button)
  .component('Select', Select)
  .component('SearchInput', SearchInput)
  .component('CardContrat', CardContrat)
  .component('ConfirmationModal', ConfirmationModal)
  .component('Loader', Loader)
  .component('PanThumb', PanThumb)
  .component('QuillEditor', QuillEditor)
  .mount('#app')