<script>
import { Icon } from "@iconify/vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();
    const isUserRole = computed(() =>
      store.getters["user/isUserRole"]("admin")
    );
    const router = useRouter();
    const handleLogout = () => {
      store.dispatch("user/logout");
      router.push("connexion");
    };

    return {
      handleLogout,
      isUserRole,
    };
  },
};
</script>

<template>
  <input
    type="checkbox"
    class="sidebar-btn-check d-none"
    id="sidebar-btn-check"
    autocomplete="off"
  />
  <div class="sidebar">
    <label for="sidebar-btn-check" class="close-sidebar pointer">Fermer</label>

    <div class="p-3">
      <a href="https://www.opencontrat.com">
        <img alt="logo" src="@/assets/logo.png" width="150" />
      </a>
    </div>
    <h5 class="ms-2 fw-bold menu">Menu</h5>
    <div class="ps-3 mt-4">
      <router-link
        to="/"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="ci:home-alt-check" />
        <span class="ms-2">Acceuil</span>
      </router-link>

      <router-link
        to="clients"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="akar-icons:people-group" />
        <span class="ms-2">Clients</span>
      </router-link>

      <router-link
        to="contrats"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="uil:file-contract" />
        <span class="ms-2">Contrats</span>
      </router-link>

      <router-link
        v-if="isUserRole"
        to="gestionnaires"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="subway:admin-1" />
        <span class="ms-2">Gestionnaires</span>
      </router-link>

      <router-link
        to="infos"
        class="d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="carbon:information-square" />
        <span class="ms-2">Infos générales</span>
      </router-link>
    </div>
    <hr class="w-100" />
    <div class="ms-2">
      <div
        @click="handleLogout"
        class="pointer d-flex justify-content-start align-items-center mb-3"
      >
        <Icon icon="heroicons-outline:logout" />
        <span class="ms-2">Se déconnecter</span>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
.sidebar {
  min-width: 250px;
  height: 100vh;
  overflow-x: hidden;
  transition: 0.3s all ease-in-out;
  .router-link-active.router-link-exact-active {
    color: #dc3545;
  }
}

.close-sidebar {
  display: none;
}
@media screen and (max-width: 772px) {
  .sidebar {
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.25);
  }
  .close-sidebar {
    display: block;
    position: absolute;
    right: 0;
  }
}
.sidebar-btn-check,
.sidebar-btn-check:checked ~ div.sidebar {
  width: 0px !important;
  min-width: 0px !important;
  height: 0px !important;
  overflow: hidden;
  transition: 0.3s all ease-in-out;
}
.menu {
  color: #20356c;
}
</style>