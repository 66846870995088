import request from './request'

export function getOffers() {
  return request({
    url: 'offers',
    method: 'get'
  })
}

export function getOffer(id) {
  return request({
    url: 'offers/' + id,
    method: 'get'
  })
}

export function updateOffer(data) {
  return request({
    url: 'admin/offers/' + data?.id,
    method: 'put',
    data
  })
}
