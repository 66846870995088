const state = () => ({
  currentCountry: "",
});
const mutations = {
  SET_COUNTRY: (state, name) => {
    state.currentCountry = name;
  },
};
const actions = {
  define_country({ commit }, payload) {
    commit("SET_COUNTRY", payload);
  },
};
const getters = {
  getCurrentCountry(state) {
    return state.currentCountry;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
