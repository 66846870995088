import request from './request'

export function login(data) {
  return request({
    url: 'admin/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: 'account',
    method: 'get'
  })
}

export function getProfile() {
  return request({
    url: 'profile',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: 'logout',
    method: 'post'
  })
}

export function updatePassword(data) {
  return request({
    url: 'admin/password',
    method: 'put',
    data
  })
}