<script>
export default {
  props: ["image", "price", "title"],
};
</script>


<template>
  <div class="card__wrapper">
    <div class="card__bg">
      <img :src="image" alt="" />
    </div>
    <div class="c-card">
      <h3 class="card__title">{{ title }}</h3>
      <p class="card__price">
        prix <span v-if="price == 0"> Gratuit </span>
        <span v-else>{{ price }} F CFA</span>
      </p>
      <p class="card__text">{{ '' }}</p>
      <div class="card__cta">
        <slot></slot>
      </div>
    </div>
  </div>
</template>


<style scoped>
.card__wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;
  margin-top: 22.5px;
  margin-bottom: 97.5px;
}

.card__bg {
  width: 100%;
  height: 292.5px;
  border-radius: 6.75px;
  overflow: hidden;
}

.card__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.07));
}

.c-card {
  background-color: #fff;
  box-shadow: 0px 18.1388px 102.031px rgba(0, 0, 0, 0.05);
  border-radius: 6.75px;
  padding: 18.75px;
  width: 90%;
  position: absolute;
  bottom: -30%;
  left: calc((100% - 90%) / 2);
  overflow: hidden;
}

.card__title {
  font-size: 0.9rem;
  margin: 0;
  color: #0d1111;
  margin-bottom: 0.75rem;
}

.card__price {
  color: #767e7e;
  font-size: 0.75rem;
  margin: 0;
}

.card__price span {
  color: #0d1111;
  font-size: 1.125rem;
  font-weight: bold;
}

.card__text {
  color: #9da6a5;
  font-size: 0.75rem;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 7.5px;
  overflow: hidden;
}

.card__cta {
  display: flex;
  gap: 15px;
  align-items: center;
}

/* .card__link {
  font-size: 0.925rem;
}

.card__link::before {
  content: "";
  background-color: currentColor;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.buy {
  color: #2696be;
  text-decoration: underline;
}

.details {
  color: #1ebfc1;
} */
</style>