<script>
export default {
  props: {
    defaulttext: String,
    modelValue: String,
  },
};
</script>

<template>
  <select
    class="form-select"
    :value="modelValue"
    @change="$emit('update:modelValue', $event.target.value)"
    aria-label="Default select example"
  >
    <option>{{ defaulttext }}</option>
    <slot></slot>
  </select>
</template>

<style scoped>
select {
  color: #000;
  border: 0px !important;
  padding-left: 0px !important;
  background-position: right 0rem center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0px !important;
}
select:focus {
  box-shadow: none !important;
}
</style>