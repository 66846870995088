<script>
import { ref, reactive, toRefs, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import {ElMessage, ElNotification} from "element-plus"
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const loading = ref(false);
    const redirect = ref(undefined);

    const state = reactive({
      loginForm: {
        email: "",
        password: "",
      },
      otherQuery: {},
    });

    //computed properties
    const getterLoginStatus = computed(
      () => store.getters["user/getLoginStatus"]
    );

    // methods
    const handleLogin = () => {
      loading.value = true;
      store
        .dispatch("user/login", state.loginForm)
        .then(() => {
          loading.value = false;
          if (getterLoginStatus.value === "success") {
            router.push({
              path: redirect.value || "/",
              query: state.otherQuery,
            });
          } else {
            ElMessage({
              showClose: true,
              message: 'Vérifiez que tous les champs demandés sont bien remplis',
              type: 'error',
            })
          }
        })
        .catch((e) => {
          if(typeof e == "string") {
            ElNotification({
              showClose: true,
              message: e,
              type: 'error',
            })
          } else {
            ElMessage({
              showClose: true,
              message: 'Vérifiez que tous les champs demandés sont bien remplis',
              type: 'error',
            })
          }
          loading.value = false;
        });
    };
    const getOtherQuery = (query) => {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    };

    //watchers
    watch(
      route,
      () => {
        const query = route.query;
        if (query) {
          redirect.value = query.redirect;
          state.otherQuery = getOtherQuery(query);
        }
      },
      {
        immediate: true,
      }
    );

    return {
      handleLogin,
      getOtherQuery,
      ...toRefs(state),
      loading,
    };
  },
};
</script>

<template>
  <div
    class="d-flex justify-content-between flex-column"
    style="height: 100vh; overflow-x: hidden"
  >
    <div class="row row-cols-1 row-cols-md-2 mb-3">
      <div class="col pt-2">
        <div class="w-75 m-auto">
          <a href="http://www.opencontrat.com">
            <img
              src="@/assets/logo.png"
              alt=""
              srcset=""
              height="80"
              class="logo"
            />
          </a>

          <div class="mb-5">
            <label for="" class="form-label">Email</label>
            <TextInput email v-model.trim="loginForm.email"/>
          </div>
          <div class="mb-5">
            <label for="" class="form-label">Mot de passe</label>
            <PasswordInput v-model="loginForm.password" />
          </div>
          <div
            class="
              mb-5
              row row-cols-1 row-cols-sm-2 row-cols-lg-2
              align-items-center
            "
          >
            <div class="col my-2">
              <Loader v-if="loading"/>
              <Button v-else @click="handleLogin()">CONNEXION</Button>
            </div>
            <div class="col my-2 d-none">
              <router-link to="#" class="fwpwd"
                >Mot de passe oublié?</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col d-none d-md-block">
        <div class="bg">
          <img src="@/assets/auth_img.png" alt="" srcset="" />
          <ul
            class="nav justify-content-end"
            style="margin-bottom: 250px; position: absolute; right: 0"
          >
            <li class="nav-item">
              <a class="nav-link" id="text-white" href="https://opencontrat.com/contactus">CONTACT</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="text-white" href="https://opencontrat.com/faq">FAQ</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row row-cols-1 row-cols-md-2 text-center mb-3">
      <div class="col">
        <div class="w-75 m-auto">
          Opencontrat {{ new Date().getFullYear() }}
        </div>
      </div>
     
    </div>
  </div>
</template>

<style scoped>
#text-white {
  color: #fff !important;
}
label {
  color: #20356c !important;
  margin-bottom: 0px !important;
}

.logo {
  margin-bottom: 120px;
}
.redirect {
  font-size: 13px;
}
.bg {
  padding-left: 20px;
  padding-bottom: 20px;
  position: relative;
}
.bg img {
  position: absolute;
  top: 0;
  right: 0;
  width: 85%;
}
.fwpwd {
  color: #ff1e1e;
  font-weight: 400;
}
</style>
