<script setup>
import { Icon } from "@iconify/vue";
import { defineProps, defineEmits } from "vue";
defineProps(["modelValue"]);
const emit = defineEmits(["onSearch"]);
const sendEvent = () => {
  emit("onSearch");
};
</script>

<template>
  <div class="d-flex input_container">
    <input
      type="text"
      class="form-control"
      placeholder="Rechercher"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <Icon
      icon="carbon:search"
      width="16"
      height="16"
      class="pointer"
      @click="sendEvent"
    />
  </div>
</template>

<style scoped>
.input_container {
  background: #ffffff;
  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 5px 10px;
  padding-left: 0;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}
input {
  color: #000;
  border: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  border-radius: 0px !important;
  width: calc(100% - 16px);
  background: transparent;
  font-weight: 200;
  font-size: 13px;
}
input:focus {
  box-shadow: none !important;
  background: transparent;
}
</style>
