<script setup>
import { getUsers, blockUser, unblockUser } from "@/http/users";
import { ElNotification, ElMessage } from "element-plus";
import { onMounted, ref } from "vue";
const lastPage = ref("");
const from = ref("");
const to = ref("");
const total = ref("");
const page = ref(1);
const perPage = ref(10);
const search = ref(null);
const currentPage = ref("");
const loading = ref(false);
const tableData = ref([]);
onMounted(() => {
  fetchUsers();
});
const fetchUsers = async () => {
  loading.value = true;
  await getUsers(page.value, perPage.value, search.value)
    .then((response) => {
      loading.value = false;
      lastPage.value = response.last_page;
      from.value = response.from;
      to.value = response.to;
      total.value = response.total;
      currentPage.value = response.current_page;
      tableData.value = response.data;
    })
    .catch(() => {
      loading.value = false;
      ElNotification({
        title: "Erreur",
        message: "Oops. Les clients n'ont pas pu être chargés",
        type: "error",
      });
    });
};
const blockHandler = async (id) => {
  loading.value = true;
  await blockUser(id)
    .then(() => {
      loading.value = false;
      fetchUsers();
    })
    .catch(() => {
      loading.value = false;
      ElMessage({
        showClose: true,
        message: "Oops. La donnée n'a pu etre enregistré. Veuillez réessayer",
        type: "error",
      });
    });
};

const unblockHandler = async (id) => {
  loading.value = true;
  await unblockUser(id)
    .then(() => {
      loading.value = false;
      fetchUsers();
    })
    .catch(() => {
      loading.value = false;
      ElMessage({
        showClose: true,
        message: "Oops. La donnée n'a pu etre enregistré. Veuillez réessayer",
        type: "error",
      });
    });
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a href="#">Opencontrat</a>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Clients ({{ total }})
        </li>
      </ol>
    </nav>
    <SearchInput v-model="search" @onSearch="fetchUsers" />
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <Loader v-if="loading" />
  <div v-else class="card border-0 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">No</th>
          <th scope="col">Nom et prénoms</th>
          <th scope="col">Téléphone</th>
          <th scope="col">Email</th>
          <th scope="col">Achat</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in tableData" :key="index">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ item?.name }}</td>
            <td>{{ item?.phone }}</td>
            <td>{{ item?.email }}</td>
            <td>{{ item?.user_contracts_count }}</td>
            <td>
              <div v-if="item.is_active">
                <el-popconfirm
                  confirm-button-text="Oui"
                  cancel-button-text="Non"
                  icon-color="red"
                  title="Bloquer ce client"
                  @confirm="blockHandler(item?.id)"
                >
                  <template #reference>
                    <el-button type="danger">Bloquer</el-button>
                  </template>
                </el-popconfirm>
              </div>
              <div v-else>
                <el-popconfirm
                  confirm-button-text="Oui"
                  cancel-button-text="Non"
                  icon-color="red"
                  title="Débloquer ce client ?"
                  @confirm="unblockHandler(item?.id)"
                >
                  <template #reference>
                    <el-button type="success">Débloquer</el-button>
                  </template>
                </el-popconfirm>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="d-flex justify-content-between align-items-center m-3">
      <ul class="pagination">
        <li>
          <p aria-label="Previous">
            <span aria-hidden="true">«</span>
          </p>
        </li>
        <template v-for="(item, index) in lastPage" :key="index">
          <li :class="item == currentPage ? 'active' : ''">
            <p @click="(page = item), fetchUsers()" class="pointer">
              {{ item }}
            </p>
          </li>
        </template>
        <li>
          <p aria-label="Next">
            <span aria-hidden="true">»</span>
          </p>
        </li>
      </ul>
      <div class="">
        <em>Affichage de {{ from }} à {{ to }} (sur {{ total }} éléments)</em>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
