<script>
import { Navbar } from "@/layout/components";
export default {
  components: {
    Navbar,
  },
};
</script>

<template>
  <div class="app_outer">
    <Navbar minimal/>
    <main class="p-3">
      <router-view />
    </main>
  </div>
</template>

<style>
.app_outer {
  width: 100%;
  background-color: #f7f8fc;
}
main {
  height: calc(100vh - 60px);
  overflow: scroll;
}
</style>

